import React, { useRef, useState } from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import WritingGemImg from "../../assets/images/generative-ai/writing-gem.webp"
import LetAIMImg from "../../assets/images/generative-ai/let-ai-m.webp"
import PerfectEmailVMImg from "../../assets/images/generative-ai/perfect-email-v.webp"
import GenerativeAIVImg from "../../assets/images/generative-ai/generative-ai-v.webp"
import GemFinderTipsIImg from "../../assets/images/generative-ai/gem-findertips-i-new.webp"
import WritingGemVid from "../../assets/images/generative-ai/Writing-with-GEM-v1.mp4"
import SummarizingImg from "../../assets/images/generative-ai/summarizing.png"
import PowerGemAiImg from "../../assets/images/generative-ai/power-gem-ai.png"
import GemAiConvos from "../../assets/images/generative-ai/convos-highlevel.png"
import PlayIconImg from "../../assets/images/play-icon.svg"
import PauseIconImg from "../../assets/images/pause-icon.svg"
import PlayImg from "../../assets/images/play-image.svg"
import VideoPlaceholderImg from "../../assets/images/videoPlaceholder.webp"
import { Link } from 'gatsby';


const GenerativeAI = ({ data }) => {
  const videoRef = useRef()
  const [stop, setStop] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleVideo = () => {
    setIsChecked(!isChecked)
    setStop(!stop)
    if (stop === true) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }

  const play = PlayIconImg
  const pause = PauseIconImg
  const checkbox = isChecked ? pause : play

  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="BlueMail GemAI | Generative Email AI"
        postDescription="BlueMail GemAI is designed to streamline the process of summarizing and writing emails in a faster and more efficient way, powered by ChatGPT."
        postImage="/img/OG/og_image-generative-ai.png"
        postURL="features-functions/generative-email"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container generative-ai r pt-120 pb-120">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
              <h1>
                <span>BlueMail </span> GemAI
              </h1>
              <h2>Generative Email AI</h2>
              <p className="m-left">
                AI capabilities are now part of the BlueMail cross-platform
                service. Email is an indispensable tool in our daily
                communication, providing a fast and easy method of sharing
                information with others. However, with the increasing number of
                emails we receive and send every day, it can be challenging to
                craft a well-written and effective email in a timely manner. To
                address this challenge, a new approach to email communication
                has emerged: Generative Email. BlueMail GemAI is filtered by
                content moderation and is based on a customized OpenAI's
                ChatGPT.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
              <img
                className=" ml-auto"
                src={GenerativeAIVImg}
                alt="Generative Email AI"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row r-head">
            <div className="col-lg-8 col-md-10 col-xs-12 headlights">
              <h2>Highlights</h2>
              <ul>
                <li>
                  BlueMail GemAI offers Generative Email across platforms.
                </li>
                <li>
                  Generative Email uses AI to help users write better emails
                  quickly and suggest responses based on context, making
                  communication more efficient.
                </li>
                <li>
                  The AI provides condensed summaries of long emails to increase
                  productivity.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container writing-with-gem pt-120">
          <div className="row m-rev">
            <div className="col-lg-6 col-md-6 col-xs-12 m-auto controls">
              <video
                ref={videoRef}
                poster={VideoPlaceholderImg}
                loop
                muted
                playsInline
              >
                <source src={WritingGemVid} type="video/mp4" alt="write-gem" />
              </video>
              {!isChecked && (
                <img
                  onClick={handleVideo}
                  className="video-play-image"
                  src={PlayImg}
                  alt="play button"
                />
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
              <h2>
                Writing <span>with GemAI</span>
              </h2>
              <p className="m-left">
                With the increasing volume of emails we receive and send,
                writing an email has become a time-consuming task. When using
                Generative Email, users can write emails faster and with more
                confidence, knowing that they have the support of AI to help
                them craft their message. AI algorithms can provide intelligent
                suggestions that can help the user craft a more effective and
                compelling message. This way, the user can save time and effort
                while ensuring that their email is well-written and conveys the
                desired tone and meaning.
              </p>
              {/* <button className='default-video-play-btn'>
                          Auto-Play On 
                          <img onClick={handleVideo} src={checkbox} alt='play button'/>
                        </button> */}
            </div>
          </div>
        </div>
        <div className="container-fluid let-ai l">
          <div className="container pt-200 pb-100">
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                <h2>
                  <span>AI</span> at your fingertips
                </h2>
                <p className="m-left">
                  Generative AI in BlueMail allows users to create emails from
                  scratch using natural language processing (NLP) algorithms,
                  which generate relevant content based on your input. Let
                  BlueMail GemAI take care of generating unique content every
                  time you need to write or reply an email. You no longer have
                  to manually type out every single word in your email.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                <img src={GemFinderTipsIImg} alt="GemAI at your fingertips" />
              </div>
            </div>
          </div>
        </div>
        <div className="container summarizing pt-100 pb-100">
          <div className="row m-rev">
            <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
              <img className="mt-3" src={SummarizingImg} alt="Summarizing" />
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
              <h2>
                <span>Summarizing</span> your email
              </h2>
              <p className="m-left">
                BlueMail GemAI can make analyzing emails easier and faster by
                providing a condensed summary. No more spending time manually
                going through long emails - users can simply click the button
                and get an instant overview of the main ideas. It's like having
                a TLDR tool at their fingertips, allowing users to use their
                time more productively.
              </p>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-50 mb-50 text-center mt-0-m">
              <h2>
                <span>Streamline Your</span> Email Workflow
              </h2>
              <p className="m-left">
                With the increasing volume of emails we receive and send,
                writing an email has become a time-consuming task. When using
                Generative Email, users can write emails faster and with more
                confidence, knowing that they have the support of AI to help
                them craft their message. It suggests the wording, sentence
                structure, vocabulary, and tone based on the context of the
                email. By analyzing the content of the email, AI algorithms can
                provide intelligent suggestions that can help the user craft a
                more effective and compelling message. This way, the user can
                save time and effort while ensuring that their email is
                well-written and conveys the desired tone and meaning.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="mt-3 mb-5"
                src={WritingGemImg}
                alt="Writing with GemAI"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid power-gem-ai mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                <h2>
                  The Power of <br />
                  <span>BlueMail GemAI</span>
                </h2>
                <p className="m-left">
                  With advanced AI technology, users can now experience a
                  faster, more efficient way of writing and replying to emails.
                  BlueMail GemAI provides context and relevant information when
                  replying to previous emails. GemAI suggests automated responses
                  based on the context of the email, saving users time and
                  effort.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                <img className="mt-3" src={PowerGemAiImg} alt="Power GemAI" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid power-gem-ai mb-80">
        <Link to='/blog/gemai-convos-ai/'>
          
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                <h2>
                  GemAI<span> Convos</span>
                </h2>
                <p className="m-left">
                Picture having an AI team member seamlessly integrated into your group emails, enhancing collaboration and productivity like never before. Available across all major platforms, GemAI Convos revolutionizes teamwork by providing real-time discussions, AI-powered insights, and synchronized communication. It's not just about efficiency; it's about fostering dynamic collaboration. GemAI Convos elevates every group email into a space for innovative problem-solving and collective intelligence. Join us in embracing the future of email communication and experience the transformation with BlueMail's GemAI Convos – your team's new AI partner ready to revolutionize how you collaborate.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                <img className="mt-3" src={GemAiConvos} alt="Power GemAI" />
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default GenerativeAI

export const query = graphql`
  query GenerativeAIPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
